var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "id": "kuisioner",
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Formulir Gizi',
        isCurrent: true
      }]
    }
  })], 1), _c('Header'), _c('c-box', {
    attrs: {
      "w": ['100%', '600px'],
      "mx": "auto",
      "bg": "#FFF",
      "p": ['1rem', '0px']
    }
  }, [_c('Steps', {
    attrs: {
      "current": 6,
      "total": 6
    }
  }), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "590px",
      "mx": "auto",
      "margin-top": ['16px', '24px']
    }
  }, [_c('c-box', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "text-transform": "uppercase",
      "font-weight": "500",
      "font-size": ['16px', '18px'],
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Formulir Gizi (6/6) ")]), _c('c-heading', {
    attrs: {
      "font-weight": "700",
      "font-size": ['18px', '28px'],
      "line-height": ['27px', '42px'],
      "color": "black.900"
    }
  }, [_vm._v(" Kondisi Pribadi & Riwayat Medis ")])], 1), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "marginTop": "24px"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'input[type=radio]:checked': {
          bg: 'primary.400',
          borderColor: 'primary.400'
        },
        'input[type=checkbox]:checked': {
          bg: 'primary.400',
          borderColor: 'primary.400'
        },
        div: {
          fontSize: ['14px', '18px']
        }
      },
      expression: "{\n            'input[type=radio]:checked': {\n              bg: 'primary.400',\n              borderColor: 'primary.400',\n            },\n            'input[type=checkbox]:checked': {\n              bg: 'primary.400',\n              borderColor: 'primary.400',\n            },\n            div: {\n              fontSize: ['14px', '18px'],\n            },\n          }"
    }]
  }, [_c('FormAccordionList', {
    attrs: {
      "allow-toggle": ""
    }
  }, [_c('FormAccordionListItem', {
    attrs: {
      "label": "Riwayat Medis",
      "is-error": !_vm.isSectionMedicalHistoryValid
    }
  }, [_c('FormCheckbox', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.mentalDisorder.$invalid,
      "label": "Apakah kamu dinyatakan dokter memiliki masalah atau gangguan berikut?",
      "options": _vm.OPTIONS_MENTAL_DISORDER,
      "disabled-option": "92",
      "is-other": true,
      "other-option": "87",
      "other": _vm.mentalDisorderOther
    },
    on: {
      "updateOther": _vm.updateMentalDisorderOther
    },
    model: {
      value: _vm.mentalDisorder_,
      callback: function callback($$v) {
        _vm.mentalDisorder_ = $$v;
      },
      expression: "mentalDisorder_"
    }
  }), _c('FormCheckbox', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.disease.$invalid,
      "label": "Adakah saat ini kamu dinyatakan mengidap salah satu kondisi di bawah ini oleh dokter?",
      "options": _vm.OPTIONS_DISEASE,
      "disabled-option": "139",
      "is-other": true,
      "other-option": "106",
      "other": _vm.diseaseOther
    },
    on: {
      "updateOther": _vm.updateDiseaseOther
    },
    model: {
      value: _vm.disease_,
      callback: function callback($$v) {
        _vm.disease_ = $$v;
      },
      expression: "disease_"
    }
  }), _c('FormCheckbox', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.symptom.$invalid,
      "label": "Adakah kamu mengalami keluhan dibawah ini dalam 3 hari terakhir?",
      "options": _vm.OPTIONS_SYMPTOM,
      "disabled-option": "107"
    },
    model: {
      value: _vm.symptom,
      callback: function callback($$v) {
        _vm.symptom = $$v;
      },
      expression: "symptom"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": false,
      "is-invalid": _vm.$v.drugsConsumption.$invalid,
      "label": "Adakah konsumsi obat-obatan rutin yang perlu kami ketahui? Jika ada, mohon sebutkan obat untuk apa atau merk obatnya",
      "placeholder": "Contoh: obat diabetes, obat hipertensi, dexamethasone, metformin, captopril."
    },
    model: {
      value: _vm.drugsConsumption,
      callback: function callback($$v) {
        _vm.drugsConsumption = $$v;
      },
      expression: "drugsConsumption"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "text",
      "is-disabled": !_vm.isEditable,
      "is-required": false,
      "is-invalid": _vm.$v.specialMedicine.$invalid,
      "label": "Adakah konsumsi jenis obat, ramuan herbal atau minuman khusus yang sedang digunakan untuk diet? Jika ada, mohon sebutkan jenisnya dan tujuan penggunaannya",
      "placeholder": "Tuliskan disini"
    },
    model: {
      value: _vm.specialMedicine,
      callback: function callback($$v) {
        _vm.specialMedicine = $$v;
      },
      expression: "specialMedicine"
    }
  }), _c('FormCheckbox', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.currentBehavior.$invalid,
      "label": "Adakah kamu termasuk salah satu dibawah ini?",
      "options": _vm.OPTIONS_CURRENT_BEHAVIOR,
      "disabled-option": "116"
    },
    model: {
      value: _vm.currentBehavior,
      callback: function callback($$v) {
        _vm.currentBehavior = $$v;
      },
      expression: "currentBehavior"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "text",
      "is-disabled": !_vm.isEditable,
      "is-required": false,
      "is-invalid": _vm.$v.supplementConsumption.$invalid,
      "label": "Adakah jenis multivitamin atau tablet suplementasi yang kamu konsumsi? Jika ada, mohon sebutkan jenisnya atau merknya",
      "placeholder": "Tuliskan disini"
    },
    model: {
      value: _vm.supplementConsumption,
      callback: function callback($$v) {
        _vm.supplementConsumption = $$v;
      },
      expression: "supplementConsumption"
    }
  }), _c('FormRadio', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.communicationIntensity.$invalid,
      "label": "Seperti apa intensitas komunikasi yang kamu inginkan dalam program?",
      "options": _vm.OPTIONS_COMMUNICATION_INTENSITY
    },
    model: {
      value: _vm.communicationIntensity,
      callback: function callback($$v) {
        _vm.communicationIntensity = $$v;
      },
      expression: "communicationIntensity"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.otherDisease.$invalid,
      "label": "Adakah riwayat penyakit atau kondisi kesehatan lainnya yang perlu kami ketahui?",
      "placeholder": "Tuliskan disini"
    },
    model: {
      value: _vm.otherDisease,
      callback: function callback($$v) {
        _vm.otherDisease = $$v;
      },
      expression: "otherDisease"
    }
  })], 1), _c('FormAccordionListItem', {
    attrs: {
      "label": "Kondisi Pribadi",
      "is-error": !_vm.isSectionPersonalConditionValid
    }
  }, [_c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.job.$invalid,
      "label": "Pekerjaan",
      "placeholder": "Pilih Pekerjaan",
      "options": _vm.OPTIONS_JOBS,
      "is-other": true,
      "other": _vm.jobOther,
      "other-option": "125"
    },
    on: {
      "updateOther": _vm.updateJobOther
    },
    model: {
      value: _vm.jobSelection,
      callback: function callback($$v) {
        _vm.jobSelection = $$v;
      },
      expression: "jobSelection"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.lastEducation.$invalid,
      "label": "Pendidikan Terakhir",
      "placeholder": "Pilih Pendidikan",
      "options": _vm.OPTIONS_LAST_EDUCATION,
      "is-other": true,
      "other": _vm.educationOther,
      "other-option": "131"
    },
    on: {
      "updateOther": _vm.updateEducationOther
    },
    model: {
      value: _vm.educationSelection,
      callback: function callback($$v) {
        _vm.educationSelection = $$v;
      },
      expression: "educationSelection"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": false,
      "is-invalid": _vm.$v.expectProgram.$invalid,
      "label": "Apa yang kamu harapkan dari program yang kamu pilih?",
      "placeholder": "Tuliskan disini"
    },
    model: {
      value: _vm.expectProgram,
      callback: function callback($$v) {
        _vm.expectProgram = $$v;
      },
      expression: "expectProgram"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "is-disabled": !_vm.isEditable,
      "is-required": false,
      "is-invalid": _vm.$v.expectNutritionist.$invalid,
      "label": "Apa lagi yang kamu harapkan dari Ahli Gizi Dietela?",
      "placeholder": "Silahkan tuliskan sikap, cara komunikasi, atau jenis-jenis informasi yang kamu harapkan dari Ahli Gizi kamu"
    },
    model: {
      value: _vm.expectNutritionist,
      callback: function callback($$v) {
        _vm.expectNutritionist = $$v;
      },
      expression: "expectNutritionist"
    }
  })], 1)], 1), _c('c-grid', {
    attrs: {
      "margin-top": "16px",
      "template-columns": "repeat(2, 1fr)",
      "gap": "3"
    }
  }, [_c('c-button', {
    attrs: {
      "w": "100%",
      "background-color": "white",
      "color": "primary.400",
      "border-color": "primary.400",
      "border-radius": "100px",
      "variant": "outline",
      "px": "30px",
      "h": "48px",
      "is-disabled": _vm.isSubmitting || _vm.isBack
    },
    on: {
      "click": _vm.onPrevious
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "w": "100%",
      "background-color": "primary.400",
      "color": "white",
      "border-radius": "100px",
      "variant": "solid",
      "px": "30px",
      "is-disabled": _vm.isDisabled || _vm.isBack,
      "is-loading": _vm.isSubmitting,
      "loading-text": "Submitting",
      "h": "48px",
      "type": "submit"
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }